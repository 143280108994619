@import "settings";
@import "_common/grid";

@import "_common/reset";
@import "_common/base";
@import "_common/typo";

@import "_parts/login-page";
@import "_parts/main-page";
@import "_parts/profile-page";


[data-debug="debug"]:after {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  z-index: 99999;
  width: 24px;
  height: 24px;
  content: 'XXL';
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: sans-serif;
  font-weight: bold;
  line-height: 1em;
  font-size: 14px;
  @include respond-down(XL) { content: 'XL'; }
  @include respond-down(L)  { content: 'L';  }
  @include respond-down(M)  { content: 'M';  }
  @include respond-down(S)  { content: 'S';  }
  @include respond-down(XS) { content: 'XS'; }
}
