$max-row-width: 7000;
$grid-columns: 12;

$XL: 1920;
$L: 1440;
$M: 1024;
$S: 768;
$XS: 576;

$grid: (
  XXL: (to: $max-row-width, from: $XL, gutter: 12),
  XL:  (to: $XL, from: $L,  gutter: 12),
  L:   (to: $L,  from: $M,  gutter: 12),
  M:   (to: $M,  from: $S,  gutter: 12),
  S:   (to: $S,  from: $XS, gutter: 12),
  XS:  (to: $XS, from: 0,   gutter: 0)
);

:export { XL: $XL; L: $L; M: $M; S: $S; XS: $XS; }
