
.main-page {
  position: relative;
  z-index: 100;
  height: 100vh;
  box-sizing: border-box;
  padding: 40px;

  .call {
    display: none;
  }

  .big-button {
    margin: 0 auto 30px auto;
    cursor: pointer;
    width: 400px;
    height: 400px;
    border-radius: 400px;
    background: #e80005 radial-gradient(ellipse at top, rgba(255,255,255,0.5), transparent);
    box-shadow: inset 0 0 20px rgba(0,0,0,0.6);
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    transition: all 0.15s ease;
    text-align: center;
    user-select: none;

    @include respond-down(M){
      width: 300px;
      height: 300px;
      font-size: 2em;
    }
    @include respond-down(XS){
      width: 240px;
      height: 240px;
      font-size: 1.7em;
    }

    .started {
      display: none;
    }

    &:active, &.active {
      background: #8f0005 radial-gradient(ellipse at top, rgba(255,255,255,0.2), transparent);
      box-shadow: inset 0 0 20px rgba(0,0,0,0.7), inset 0 0 90px rgba(0,0,0,0.7);
      color: #eee;
    }

    &.call-process {
      background: #008f1b radial-gradient(ellipse at top, rgba(255,255,255,0.2), transparent);

      .start {
        display: none;
      }
      .started {
        display: initial;
      }
    }

  }

  .button-cancel {
    display: none;
    margin: 0 auto 30px auto;
    font-size: 18px;
    font-weight: bold;
    
    span {
      color: #E00;
    }
  }

  .profile-edit {
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 40px;
    bottom: 40px;
    font-weight: bold;

    @include respond-down(S){
      right: 28px;
      bottom: 28px;
    }

    svg {
      margin-right: 8px;
    }
  }

  .logo {
    display: block;
    margin: 0 auto 50px auto;
    width: 200px;
  }
}
