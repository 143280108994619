.login-page {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 200;
  transition: opacity 0.35s ease, transform 0.35s ease;

  &.out {
    transform: scale(1.3);
    opacity: 0;
  }

  .logo {
    width: 200px;
    display: block;
    margin: 0 auto 20px auto;
  }

  .login-box {
    background: #ffd804;
    padding: 20px 20px;
    margin: 0 0 30px 0;
    border-radius: 5px;
    box-shadow:
            0 2.8px 2.2px rgba(0, 0, 0, 0.02),
            0 6.7px 5.3px rgba(0, 0, 0, 0.028),
            0 12.5px 10px rgba(0, 0, 0, 0.035),
            0 22.3px 17.9px rgba(0, 0, 0, 0.042),
            0 41.8px 33.4px rgba(0, 0, 0, 0.05),
            0 100px 80px rgba(0, 0, 0, 0.07);
    width: 360px;

    @include respond-down(XS){
      width: 320px;
      padding: 16px 16px;
    }

    .response span {
      display: block;
      text-align: center;
      border: 1px dashed #F00;
      border-radius: 3px;
      margin-bottom: 17px;
      padding: 10px;
      background: rgba(250,0,0,0.1);
      color: #900;
    }

    .block {
    }

    .btn {
      margin-top: 20px;
      display: block;
      width: 100%;
    }
  }
}
