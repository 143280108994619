
.form-control {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  line-height: 1.35em;
  font-size: 16px;
  border: 1px solid #b1b1b1;
  padding: 6px 10px;
  border-radius: 3px;
  transition: box-shadow 0.25s ease, border 0.25s ease;

  &:hover {
    border-color: #1100ba;
  }

  &:focus {
    border-color: #1100ba;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #FFF;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    border-color: #1100ba;
  }
  &:active {
    border-color: #1100ba;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &.btn-black {
    background: #274d99;
    color: #FFF;
    font-weight: bold;
    letter-spacing: 0.05em;
  }

  &.btn-danger {
    width: 100%;
    background: #cb1628;
    color: #FFF;
    font-weight: bold;
    letter-spacing: 0.05em;

    &:hover {
      border-color: #861010;
    }
    &:active {
      border-color: #3d0600;
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 32, 0.25);
    }
  }
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

.form-control {
  margin-bottom: 12px;
}
