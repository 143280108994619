* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  position: relative;
  user-select: none;
  font-family: "Source Sans Pro",sans-serif;
  font-size: 18px;
  color: #232323;
  background: #ffd804;
  &::selection {}

  overflow: hidden;

  @include respond-down(M){
    overflow: auto;
    overflow-x: hidden;
  }
}

a {
  text-decoration: none;
  transition: color .25s ease;
}

.body {
}

.error-page {
  text-align: center;
  font-size: 4em;
  font-weight: 100;
  padding-top: 20%;
}

.mobile-only {
  background: #ffd804;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;

  @include respond-down(M){
    display: none;
  }
}