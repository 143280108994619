.profile-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 40px;
  z-index: 500;
  overflow: auto;
  display: none;
  opacity: 0;
  transform: scale(1.2);
  transition: opacity 0.35s ease, transform 0.35s ease;

  &.open {
    opacity: 1;
    transform: scale(1);
  }

  @include respond-down(S){
    padding: 28px;
  }

  .info {
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    line-height: 1.25em;
    a {
      color: #000;
    }

    .ext {
      white-space: nowrap;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  .close {
    position: absolute;
    right: 40px;
    top: 30px;
    padding: 3px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #274d99;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-down(S){
      right: 28px;
      top: 18px;
    }

    svg {
      width: 40px;
      height: 40px;
      path {
        fill: #FFF;
      }
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  label {
    font-size: 17px;
  }

  .form {
    margin-bottom: 40px;

    .button-line {
      display: flex;
      align-items: center;

      @include respond-down(S){
        flex-direction: column;
      }

      .btn {
        margin-right: 20px;

        @include respond-down(S){
          width: 100%;
          margin: 0 0 12px 0;
        }
      }
    }
  }
}